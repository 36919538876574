import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Pagination } from "@mui/material";
import withStyles from "@material-ui/core/styles/withStyles";
import stepSkusStyles from "assets/jss/material-dashboard-pro-react/components/stepSkusStyles";
import SkuItem from "components/OrderRequest/Skus/skuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import AbstractComponent from "../../OrderRequest/abstract";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

/**
 * This is a React component that renders a form step for selecting and adding SKUs to a shipment, with
 * various input fields and pagination.
 * @returns A functional component called SkusStep, wrapped with withStyles HOC and styled with
 * stepSkusStyles. It takes in several props including classes, shipto, auxShipto, materialGroup,
 * handlegetSkus, token, skus, setSkus, auxSkus, setAuxSkus, data, setData, amount, setAmount,
 * setShearchSkus, she
 */
const SkusStep = ({
  classes,
  shipto,
  auxShipto,
  materialGroup,
  handlegetSkus,
  token,
  skus,
  setSkus,
  auxSkus,
  setAuxSkus,
  data,
  setData,
  amount,
  setAmount,
  setShearchSkus,
  shearchSkus,
  allSkus,
  handlegetAllSkus,
  handleAddSku,
  auxbuttonsIdSkus,
  handleDeleteSku,
  setAuxbuttonsIdSkus,
  setMaterialGroupSelected,
  materialGroupSelected,
  existSkuButton,
  setExistSkuButton,
  handleGetMaterialGroup,
  firstModel,
  handleSelectAuxShipto,
  numberPageSkus,
  setPageSkus,
  pageSkus,
  skusAux,
  auxwherehouse_inv,
  setNumberPageSkus,
  handleGetKits,
  namesKits,
  handleGetSkusByKits,
  orderSkusByKits,
  // setOrderSkusByKits,
  handleDeleteOrderList,
}) => {
  const [selectauxShipto, setSelectauxShipto] = useState("");
  const [selectauxShiptoName, setSelectauxShiptoName] = useState("");
  const [valueAutocomplete, setValueAutocomplete] = useState("");
  const [kits, setKits] = useState([]);

  const handleChange = (e) => {
    setSelectauxShipto(e.target.value);
    handleSelectAuxShipto(e.target.value);
  };

  const handleChange1 = (kitName, value) => {
    setKits((prev) => {
      let newKits = [...prev];
  
      if (Number(value) === 0) {
        return newKits.filter((kit) => kit.kitName !== kitName); 
      } 
  
      const index = newKits.findIndex((kit) => kit.kitName === kitName);
      
      if (index !== -1) {
        newKits[index] = { ...newKits[index], quantity: Number(value) };
      } else {
        newKits.push({ kitName, quantity: Number(value) });
      }
  
      return newKits;
    });
  };

  useEffect(
    async () => {
      await handleGetSkusByKits(token, selectauxShipto, kits);
    },
    [kits]
  );

  const handleChangePage = (pageSkus) => {
    setPageSkus(pageSkus - 1);
  };

  /* This is a useEffect hook that runs whenever the dependencies `shearchSkus` or `pageSkus` change. It
 first checks if `selectauxShipto` and `shearchSkus` are truthy, and if so, it calls the
 `handlegetSkus` function with the `token` and `selectauxShipto` as arguments. If they are not
 truthy, it sets the `skus` state to an empty array and calls the `setNumberPageSkus` function. */
  useEffect(
    async () => {
      if (selectauxShipto && shearchSkus) {
        await handlegetSkus(token, selectauxShipto);
      } else {
        setSkus([]);
        setNumberPageSkus();
      }
      let nameShipto = "";
      for (let item of auxShipto) {
        if (item.ship_to == selectauxShipto) {
          nameShipto = item.applicant_name;
        }
      }
      setSelectauxShiptoName(nameShipto);
    },
    [/* selectauxShipto, */ shearchSkus, /* materialGroupSelected, */ pageSkus]
  );
  /* This is a useEffect hook that runs whenever the `materialGroupSelected` dependency changes. It calls
the `handlegetAllSkus` function with the `token` and `selectauxShipto` as arguments. This hook is
used to fetch all SKUs based on the selected material group. */
  /*   useEffect(
    async () => {
      await handlegetAllSkus(token, selectauxShipto);
    },
    [materialGroupSelected]
  ); */

  /* This `useEffect` hook is resetting the `shearchSkus` and `valueAutocomplete` states to `null`
whenever the `selectauxShipto` dependency changes. This is likely done to clear any previous search
results and input values when the user selects a new "Ship to" option. */
  useEffect(
    () => {
      setShearchSkus(null);
      setValueAutocomplete(null);
    },
    [selectauxShipto]
  );

  /* This is a `useEffect` hook that runs once when the component mounts. It calls the
`handleGetMaterialGroup` function with the `token` argument to fetch the material groups data. The
`async` keyword is used to allow the use of `await` inside the function. The empty array `[]` passed
as the second argument ensures that the effect only runs once. */
  useEffect(async () => {
    await handleGetMaterialGroup(token);
  }, []);

  useEffect(async () => {
    await handleGetKits(token);
    // await handleGetSkusByKits(token, selectauxShipto, kit);
  }, []);

  /* The above code is rendering a component that displays a form for selecting products. It includes a
  dropdown menu for selecting a "Ship to" option, an autocomplete input for selecting a product
  family, and another autocomplete input for selecting specific product models. It also includes a
  list of SkuItems, which are individual product items that can be added or removed from the form.
  The component includes pagination for navigating through a list of available product models. */
  return (
    <div>
      <Grid xs={12} className={classes.tittle}>
        <h5>2.Seleccionar productos</h5>
      </Grid>
      <Grid container xs={12} className={classes.selects}>
        <Grid item xs={1} />
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Ship to</InputLabel>
            <Tooltip
              placement="top"
              describeChild={true}
              title=" Por favor escoja entre las siguientes opciones: Shipto,
              familia/kit y modelo ó Shipto y modelo"
            >
              <Select
                className={classes.Select1}
                labelId="demo-simple-select-label"
                id="selectauxShipto"
                value={selectauxShipto}
                label="Seleccionar Ship to"
                onChange={handleChange}
                sx={{
                  "@media (max-Width: 960px)": {
                    width: 90,
                    height: 50,
                  },
                }}
              >
                {auxShipto.map((auxShipto, id) => (
                  <MenuItem value={auxShipto.ship_to} key={id}>
                    {auxShipto.applicant_name}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <Autocomplete
            options={materialGroup}
            autoHighlight
            onChange={(e, newValue) => {
              setMaterialGroupSelected(newValue?.value);
            }}
            sx={{ width: "90%" }}
            renderInput={(params) => <TextField {...params} label="Familia" />}
          />
        </Grid>
      </Grid>
      <Grid xs={12} className={classes.materialGroup}>
        <Box>
          <Autocomplete
            options={allSkus}
            value={valueAutocomplete}
            autoHighlight
            onChange={(e, newValue) => {
              setShearchSkus(newValue?.value);
              setValueAutocomplete(newValue?.label);
              setPageSkus(0);
            }}
            sx={{ width: "90%" }}
            renderInput={(params) => <TextField {...params} label="Modelos" />}
          />
        </Box>
      </Grid>

      {selectauxShipto && (
        <Grid container xs={12} className={classes.CheckboxContainer}>
          <Box>
          <FormGroup className={classes.CheckboxGroup}>
  {namesKits?.map((namesKit) => {
    const selectedKit = kits.find((kit) => kit.kitName === namesKit.kit);
    
    return (
            <FormControlLabel
              key={namesKit.kit}
              label={namesKit.kit}
              control={
                <Tooltip
                  placement="top"
                  describeChild={true}
                  title="Por favor escoja el kit de manera inicial, posterior podrá escoger los skus respectivos"
                >
                  <TextField
                    type="number"
                    size="small"
                    sx={{ width: "60px" }}
                    value={selectedKit?.quantity || 0}
                    onChange={(e) => {
                      let value = Number(e.target.value);
                      if (value > 10) value = 10;
                      if (value < 0) value = 0;
                      handleChange1(namesKit.kit, value)
                      }
                    }
                    inputProps={{ min: 0 , max: 20}}
                  />
                </Tooltip>
              }
            />
          );
        })}
      </FormGroup>
          </Box>
        </Grid>
      )}

      <Grid container>
        <Grid className={classes.container} xs={8}>
          {skus.map((skuslist, idx) => (
            <>
              <SkuItem
                skus={skuslist}
                auxSkus={auxSkus}
                setAuxSkus={setAuxSkus}
                id={skuslist.id ? skuslist.id : skuslist.id_mp}
                setData={setData}
                data={data}
                selectauxShipto={selectauxShipto}
                setAmount={setAmount}
                amount={amount}
                selectauxShiptoName={selectauxShiptoName}
                handleAddSku={handleAddSku}
                auxbuttonsIdSkus={auxbuttonsIdSkus}
                handleDeleteSku={handleDeleteSku}
                setAuxbuttonsIdSkus={setAuxbuttonsIdSkus}
                existSkuButton={existSkuButton}
                setExistSkuButton={setExistSkuButton}
                firstModel={firstModel}
                shearchSkus={shearchSkus}
                materialGroupSelected={materialGroupSelected}
                pageSkus={pageSkus}
                skusList={skus}
                skusAux={skusAux}
                auxwherehouse_inv={auxwherehouse_inv}
                ArraySkus={skus}
                idx={idx}
                orderSkusByKits={orderSkusByKits}
              />
            </>
          ))}
        </Grid>
        <Grid xs={4} className={classes.colum2}>
          <h4 className={classes.txtResumen}>Resumen</h4>
          {[...orderSkusByKits, ...auxSkus]?.map((item, index) => (
            <AbstractComponent
              model={item.model}
              description={item.material_description}
              qty={item.amountTaken || item.quantity || item.backorderQty}
              warehouse={item.warehouse_nearby}
              warehouse_inv={item.warehouse_inv}
              deleteCard={handleDeleteOrderList}
              index={index}
              id={item.id}
            />
          ))}
        </Grid>
      </Grid>
      {/* This code is rendering a Pagination component from the Material-UI library. The `hideNextButton`
    and `hidePrevButton` props are set to `true`, which hides the next and previous buttons
    respectively. The `onChange` prop is set to a function that calls the `handleChangePage`
    function with the text content of the clicked page as an argument. The `count` prop is set to a
    value that determines the total number of pages based on the `numberPageSkus` state. If
    `numberPageSkus` is less than 10, the count is set to 1. Otherwise, the count is calculated by
    dividing `numberPageSkus` by 10 and adding 1 if there is a remainder. The `page` prop is set to
    `pageSkus + 1`, which determines the currently active page. */}
      <Pagination
        hideNextButton={true}
        hidePrevButton={true}
        onChange={(e) => handleChangePage(e.target.textContent)}
        count={
          parseInt(numberPageSkus) < 10
            ? 1
            : parseInt(numberPageSkus) % 10 != 0
              ? parseInt(numberPageSkus / 10) + 1
              : parseInt(numberPageSkus / 10)
        }
        page={pageSkus + 1}
      />
    </div>
  );
};

export default withStyles(stepSkusStyles)(SkusStep);
